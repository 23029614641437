export const titleDropdown = ["Mr.", "Ms.", "Mrs."];

export const businessNatureDropdown = [
  "Buyers",
  "Distributors",
  "Department Stores",
  "Importers",
  "Manufacturers",
  "Designers",
  "Exporters",
  "Wholesalers Press",
  "Traders Retailers",
  "Others",
];

export const industryDropdown = [
  "Apparel Manufacturing",
  "Handbags",
  "Test, Inspection & Quality Control",
  "Textile & Fabric",
  "Knitting",
  "Print Studios",
  "Weaving & Spinning",
  "Home Textiles",
  "Haute Couture / Embroideries",
  "Footwear",
  "Furniture and Interiors",
  "Dress Makers / Tailors",
  "Printing Machinery",
  "Leather",
  "Others",
];

export const categoryDropdown = [
  "Ladieswear",
  "Ethical wear",
  "Home Textiles",
  "Menswear",
  "Sports and Casual wear",
  "Footwear",
  "Kids wear",
  "Innerwear",
  "Handbags",
  "Garment Accessories",
  "Corporate wear",
  "Others",
];

export const productsDropdown = [
  "Fabrics",
  "Eco friendly & Sustainable fabrics",
  "Designs, Styles & Trends",
  "Prints",
  "Jacquard Fabrics",
  "Corporate Wear - Uniforms",
  "Linen",
  "Knitted Materials",
  "Lingerie",
  "Functional Fabrics",
  "Denim",
  "Footwear",
  "Trims & Accessories",
  "Fibers - Yarn",
  "Handbags",
  "Textile Machinery",
  "Embroideries, Lace and Haute Couture",
  "Home & Household Textiles",
  "Full Package",
  "Others",
];

export const visitPurposeDropdown = [
  "To evaluate show for future participation",
  "Obtain technical/market information",
  "Look for distribution opportunity",
  "Place order on the day",
  "Look for new technologies/ products/services",
  "See existing suppliers",
  "Look for new alternative suppliers",
  "Others",
];

export const showInfoDropdown = [
  "Invite from Organiser",
  "Editorial in the Press",
  "Postal from Organiser",
  "Internet",
  "Invite from Exhibitor",
  "Word of Mouth",
  "Advertising by the Press",
  "Others",
];
