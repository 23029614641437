import React, { useEffect, useState } from "react";
import Select from "react-select";

const CountrySelect = ({ selectedCountry, setSelectedCountry }) => {
  const [countries, setCountries] = useState([]);
  useEffect(() => {
    fetch(
      "https://valid.layercode.workers.dev/list/countries?format=select&flags=false&value=code"
    )
      .then((response) => response.json())
      .then((data) => {
        setCountries(data.countries);
      });
  }, []);

  return (
    <Select
      options={countries}
      value={countries.find((country) => country.value === selectedCountry)}
      onChange={(selectedOption) => setSelectedCountry(selectedOption)}
    />
  );
};

export default CountrySelect;
